import { useModelStore } from "@/store/models";
import { useProductStore } from "@/store/products";
import { useUserStore } from "@/store/user";
import { useCategoryStore } from "@/store/categories";
import { useResourceStore } from "@/store/resources";
import { useFileTypeStore } from "@/store/filetypes";
import { useSettingStore } from "@/store/settings";
import { useProjectStore } from "@/store/projects";
import { useMarketingMaterialStore } from "@/store/marketingMaterial";
import { useBySpacesStore } from "./bySpaces";
import { useHomeStore } from "./home";

export default function initializeStores(){
    if(localStorage.getItem('user') !== null){
        const { init: initProducts } = useProductStore();
        const { init: initHome } = useHomeStore();
        const { init: initModels } = useModelStore();
        const { init: initCategories } = useCategoryStore();
        const { init: initUser } = useUserStore();
        const { init: initResources } = useResourceStore();
        const { init: initFileTypes } = useFileTypeStore();
        const { init: initMarketingMaterials } = useMarketingMaterialStore();
        const { init: initSettings } = useSettingStore();
        const { init: initProjects } = useProjectStore();
        const { init: initBySpaces } = useBySpacesStore();
        
        initHome();
        initUser()
        initSettings()
        initProducts()
        initCategories()
        initModels()
        initResources()
        initFileTypes()
        initMarketingMaterials()
        initProjects()
        initBySpaces()
    }
    
}



