import { defineStore, storeToRefs } from "pinia";
import WordPressAPIService from "@/services/WordPressService";
import { useProductStore } from "./products";
import { useFileTypeStore } from "./filetypes";
import { useMarketingMaterialStore } from "./marketingMaterial";
import { useModelStore } from "./models";
import { useUserStore } from "./user";
import { useProjectStore } from "./projects";
import { useCategoryStore} from '@/store/categories';
import { useBySpacesStore } from '@/store/bySpaces';
export const useResourceStore = defineStore({
  id: "resourceStore",
  state: () => ({
    resources: [],
    selected: [],
    excluded: [
      "PCT", // Product Catalogues
      "CTF", // Certificates
      "CTP", // Certificates Producto
      "CTV", // Certificates Viccarbe
      "TIN", // Technical Info
      "HTU", // How To Use
    ],
  }),
  getters: {
    getResources: (state) => state.resources,
    getResourcesFromFilters: (state) => {
      const { isPress } = storeToRefs(useUserStore());
      const { getProductBySlug } = useProductStore();
      const { getFileTypeBySlug } = useFileTypeStore();
      const { getMarketingMaterialBySlug } = useMarketingMaterialStore();
      const { getModelBySlug } = useModelStore();
      const { getBySpacesBySlug } = useBySpacesStore();
      const currentParams = state.$router.currentRoute.value.params;

      let activeProducts = currentParams?.categories;
      let activeFileTypes = currentParams?.fileTypes;
      let activeMarketingMaterials = currentParams?.marketingMaterials;
      let activeModel = currentParams?.model;
      let activeBySpaces = currentParams?.bySpaces;

      let filteredResources = state.resources;

      if (activeModel) {
        const model = getModelBySlug(activeModel);
        if (model)
          filteredResources = filteredResources.filter((resource) => {
            const pressExcludedFileTypes = ["2DF", "3DF"];
            if (typeof resource.acf.modelos === "string") return false;
            if (resource.acf.tipo === "galeria") return false;
            if (
              isPress.value &&
              resource.tipo_recurso_extras
                .map((element) => element.id_ap)
                .some((element) => pressExcludedFileTypes.includes(element))
            )
              return false;
            return resource.acf.modelos
              ?.map((modelo) => modelo.ID)
              .includes(model.id);
          });
      } else {
        if (activeProducts) {
          //Map product slug for swap by product id
          activeProducts = activeProducts
            .map((slug) => getProductBySlug(slug)?.id)
            .filter((element) => element !== undefined);
          // Filtering resources by product id
          filteredResources = filteredResources.filter((resource) =>
            resource.product_tipo.some((resourceId) =>
              activeProducts.includes(resourceId)
            )
          );
        }
        
        if (activeFileTypes) {
          // Map file type slug for swap by file type id
          const fileTypeObjects = activeFileTypes
            .map((slug) => getFileTypeBySlug(slug))
            .filter((element) => element !== undefined);

          const activeFileTypeIds = fileTypeObjects.map((obj) => obj.id);

          // Filtering resources by file type id
          filteredResources = filteredResources.filter((resource) =>
            resource.tipo_recurso.some((resourceId) =>
              activeFileTypeIds.includes(resourceId)
            )
          );

          // Adding category name to each resource
          filteredResources = filteredResources.map((resource) => {
            const validCategory = resource.tipo_recurso_extras.find(
              (extra) => extra.id !== 915 && extra.id !== 1264 && extra.id !== 1044 && extra.id !== 1273
            );
          
            return {
              ...resource,
              categoryName: validCategory ? validCategory.name : 'Categoría no encontrada',
            };
          });
        }

        if (activeMarketingMaterials) {
          //Map product slug for swap by product id
          activeMarketingMaterials = activeMarketingMaterials
            .map((slug) => getMarketingMaterialBySlug(slug)?.id)
            .filter((element) => element !== undefined);
          // Filtering resources by product id
          filteredResources = filteredResources.filter((resource) =>
            resource.tipo_recurso.some((resourceId) =>
              activeMarketingMaterials.includes(resourceId)
            )
          );
        }

        if (activeBySpaces) {
          // Map spaces slug for swap by spaces id
          const bySpacesObjects = activeBySpaces
            .map((slug) => getBySpacesBySlug(slug))
            .filter((element) => element !== undefined);
        
          const activeBySpacesIds = bySpacesObjects.map((obj) => obj.id);

          // Filtering resources by spaces id
          filteredResources = filteredResources.filter((resource) =>
            resource.tipo_recurso.some((resourceId) =>
              activeBySpacesIds.includes(resourceId)
            )
          );

          // Adding category name to each resource
          filteredResources = filteredResources.map((resource) => {
            const category = bySpacesObjects.find((obj) =>
              resource.tipo_recurso.includes(obj.id)
            );
            return {
              ...resource,
              categoryName: category ? category.name : 'Categoría no encontrada'
            };
          });
        
        }
        
        // Order resources by type and then by name. Type order is defined by ActiveFileTypes order
        if (activeFileTypes) {
          filteredResources = filteredResources.sort((a, b) => {
            const aIndex = activeFileTypes.indexOf(a.tipo_recurso[0]);
            const bIndex = activeFileTypes.indexOf(b.tipo_recurso[0]);
            if (aIndex === bIndex) {
              // if( a.tipo_recurso_extras.map(element => element.id_ap).includes('SAM') &&
              //   b.tipo_recurso_extras.map(element => element.id_ap).includes('SAM') ) {
              //     return (
              //       // SI ES 'pdf' -> indexOf('pdf')+1  === 1
              //       // NO ES 'pdf' -> indexOf('pdf')+1  === 0
              //       // LA RESTA DE AMBOS                ->  -1, 0, 1
              //       ((b.acf.recurso.subtype.indexOf('pdf')+1) - (a.acf.recurso.subtype.indexOf('pdf')+1)) * 10
              //       + a.title.rendered.localeCompare(b.title.rendered)
              //     );
              // } else {
              //     return a.title.rendered.localeCompare(b.title.rendered);
              // }
              return a.title.rendered.localeCompare(b.title.rendered);
            } else {
              return aIndex - bIndex;
            }
          });
        }
      }

      return filteredResources;
    },
    getResourcesByTerms: (state) => (urlTerms) =>
      state.resources.filter((resource) => {
        const { getCategoryById } = useCategoryStore();
        const normalizeString = (str) => {
          return str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
        };
        let terms = urlTerms.map((term) =>
          normalizeString(term).replaceAll("-", " ")
        );
        let tags = resource.tag_modelo_names.map((tag) =>
          normalizeString(tag)
        );
        let categoryName = resource.product_tipo.length === 0 ? undefined : getCategoryById(resource.product_tipo[0])?.name ;
        let keys = categoryName
          ? [normalizeString(resource.title.rendered), normalizeString(categoryName), ...tags]
          : [normalizeString(resource.title.rendered), ...tags]

        // Filtro: Term1 AND Term2 AND ...
        return terms.every((term) => keys.some((key) => key.includes(term)));

        // resource.tag_modelo_names
        //   .map((element) => element.toLowerCase())
        //   .some((term) =>
        //     terms
        //       .map((term) => term.toLowerCase().replaceAll("-", " "))
        //       .includes(term)
        //   );
      }),
    getSingleResources: (state) =>
      state.getResourcesFromFilters
        .filter((element) =>
          element.tipo_recurso_extras
            .map((element) => element.id_ap)
            .some((element) => !state.excluded.includes(element))
        )
        .sort((a, b) =>
          a.tipo_recurso_extras[0]?.name.localeCompare(
            b.tipo_recurso_extras[0]?.name
          )
        ),
    getSpecialResources: (state) =>
      state.getResourcesFromFilters
        .filter((element) =>
          element.tipo_recurso_extras
            .map((element) => element.id_ap)
            .some((element) => state.excluded.includes(element))
        )
        .map((element) => {
          const modelStore = useModelStore();
          const model = modelStore.getModelBySlug(
            element.acf.modelos[0].post_name
          );
          const category = element.tipo_recurso_extras.map(
            (element) => element.id_ap
          );
          let image = undefined;

          if (category.includes("PCT"))
            // PCT = Product Catalogues
            image = model.acf?.imagen_catalogue_ap?.sizes?.thumbnail;
          if (category.includes("CTF"))
            // CTF = Certificates
            image = model.acf?.imagen_certificates_ap?.sizes?.thumbnail;
          if (category.includes("TIN"))
            // TIN = Technical Info
            image = model.acf?.imagen_it_ap?.sizes?.thumbnail;
          element["acf"] = {
            ...element["acf"],
            imagen: {
              sizes: {
                thumbnail: image,
              },
            },
          };
          return element;
        }),
    getResourceById: (state) => (resourceId) =>
      state.resources.find((element) => {
        return element.id == resourceId;
      }),
    isResourceSelected: (state) => (resourceId) => {
      const resource = state.selected.find(
        (element) => element.id === resourceId
      );
      return resource !== undefined && resource.tipo !== "proyecto";
    },
    isResourceProjectSelected: (state) => (resourceId) => {
      const resource = state.selected.find(
        (element) => element.id === resourceId
      );
      return resource !== undefined && resource.tipo === "proyecto";
    },
    countSelectedResources: (state) => state.getSelectedResources.length,
    //getSelectedResources: (state) => state.resources.filter(element => state.selected.map(element => element.id).includes(element.id)),
    getSelectedResources: (state) =>
      state.selected
        .filter((element) => {
          if (element.tipo === "proyecto") {
            const projectStore = useProjectStore();
            return projectStore.getProjectById(element.id) !== undefined;
          } else {
            return state.getResourceById(element.id) !== undefined;
          }
        })
        .map((element) => {
          return {
            ...element,
            ...state.resources.find((resource) => resource.id === element.id),
          };
        }),
    getAsocciateToModelResources: (state) => (modelSlug) =>
      state.resources.filter((element) => {
        let state = false;
        if (typeof element.acf.modelos === "object") {
          element.acf.modelos.forEach((modelo) => {
            if (modelo.post_name === modelSlug) state = true;
          });
        }
        return state;
      }),
    getAssociateToModelCustomType: (state) => (modelSlug, type) =>
      state
        .getAsocciateToModelResources(modelSlug)
        .find((element) =>
          element.tipo_recurso_extras
            .map((element) => element.name)
            .includes(type)
        ),
    getGalleryResources: (state) => {
      const { getNestedGalleries } = useFileTypeStore();
      const galleries = { ...getNestedGalleries }; // Using spread because we don't want to modify the original object
      const assignModels = (gallery) => {
        gallery.resources = state.resources.filter((element) =>
          element.tipo_recurso.includes(gallery.id)
        );
        if (gallery.children?.length > 0) {
          gallery.children.forEach((child) => assignModels(child));
        }
      };
      assignModels(galleries);
      return galleries;
    },
    getGalleryFromModel: (state) => (modelSlug) => {
      console.log("modelSlug", modelSlug);
      const galleryResources = JSON.parse(
        JSON.stringify(state.getGalleryResources)
      );
      const galleries = Object.values({ ...galleryResources.children });
      galleries.forEach((gallery) => {
        gallery.resources = gallery.resources.filter((resource) =>
          resource.acf.modelos?.some((model) => model.post_name === modelSlug)
        );
      });
      console.log("galleries", galleries);
      console.log(
        "filteredGalleries",
        galleries.filter((gallery) => gallery.resources.length > 0)
      );
      return galleries.filter((gallery) => gallery.resources.length > 0);
      //   console.log("galleryResources", galleryResources);
      //   const findInDepth = (gallery) => {
      //     gallery.resources = gallery.resources.filter((element) =>
      //       element.acf.modelos?.some(
      //         (element) => element.post_name === modelSlug
      //       )
      //     );
      //     if (gallery.children?.length > 0) {
      //       gallery.children.forEach((child, index) => {
      //         findInDepth(child);
      //         if (child.resources.length === 0) gallery.children.splice(index, 1);
      //       });
      //     }
      //   };
      //   findInDepth(galleryResources);
      //   return { ...galleryResources };
    },
    // getSelectedResourcesInDepth: (state) => {
    //     const elements = []
    //     state.selected.forEach(resource => {
    //         if (resource.tipo === 'proyecto') {
    //             resource.resources.forEach(projectResource => {
    //                 elements.push({ id: projectResource.id, tipo: projectResource.acf.tipo })
    //             })
    //         }
    //         else elements.push(resource)
    //     })
    //     console.log(elements)
    //     return elements;
    // }
  },
  actions: {
    async init() {
      // this.resources = JSON.parse(
      //   localStorage.getItem("resourceStore")
      // ).resources;
      //console.log(this.getSingleResources)
      //this.resources = await WordPressAPIService.getAllPages('/recurso');
    },
    selectResource(resource, format = undefined, resolution = undefined) {
      //console.log(resource)
      // Remove first if exists
      if (resource.acf.tipo === "proyecto") {
        const index = this.selected.findIndex(
          (element) => element.id === resource.id && element.tipo === "proyecto"
        );
        if (index >= 0) this.selected.splice(index, 1);
      } else {
        const index = this.selected.findIndex(
          (element) => element.id === resource.id && element.tipo !== "proyecto" && element.format === format
        );
        if (index >= 0) this.selected.splice(index, 1);
      }

      let data = undefined;
      // Add after remove
      if (resource.acf.tipo === "proyecto") {
        console.log(resource);
        data = {
          id: resource.id,
          tipo: resource.acf.tipo,
          resources: resource.resources,
        };
      } else {
        data = { id: resource.id, tipo: resource.acf.tipo };
        if (format && resolution) {
          data.format = format;
          data.resolution = resolution;
        }
      }

      this.selected.push(data);
      //console.log(this.getSelectedResourcesInDepth)
      WordPressAPIService.addResourcesToSelection(this.selected).catch(() => {
        // Remove resource
        this.selected.pop();
      });
    },
    deselectResource(resource) {
      let index = this.selected.findIndex((element) => element.id === resource.id);
      while (index >= 0) {
        this.selected.splice(index, 1);
        index = this.selected.findIndex((element) => element.id === resource.id);
      }
      WordPressAPIService.addResourcesToSelection(this.selected);
    },
    deselectResourceFormat(resource) {
      let index = this.selected.findIndex((element) => element.id === resource.id && element.format === resource.format);
      if(index >= 0) {
        this.selected.splice(index, 1);
      }
      WordPressAPIService.addResourcesToSelection(this.selected);
    },
    removeAllFromSelection() {
      this.selected = [];
      WordPressAPIService.addResourcesToSelection(this.selected);
    },
    downloadSelection() {
      return WordPressAPIService.downloadAllSelectedResources().then(
        () => (this.selected = [])
      );
    },
    generateShareLink() {
      return WordPressAPIService.generateShareLink().then(
        () => (this.selected = [])
      );
    },
  },
  persist: {
    enabled: false,
    strategies: [
      {
        storage: localStorage,
        paths: ["selected"],
        key: "resourceStoreSelected",
      },
    ],
  },
});
