import { defineStore } from 'pinia'

export const useBySpacesStore = defineStore({
    id: 'bySpacesStore',
    state: () => ({
        bySpaces: [],
    }),
    getters: {
        getBySpaces: state => state.bySpaces,
        getBySpacesBySlug: state =>
            slug => state.bySpaces.find(bySpace => bySpace.slug === slug),
        getBySpacesName: state => {
            let bySpaces = [];
            state.bySpaces.forEach(bySpace => bySpaces.push(bySpace.name))
            return bySpaces;
        },
    },
    actions: {
        async init(){
            let bySpaces = JSON.parse(localStorage.getItem('bySpacesStore')).bySpaces;
            this.bySpaces = bySpaces;
        },
    },
    persist:{
        enabled: true
    }
})