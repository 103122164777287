<template>
    <div class="navigation">
        <div class="breadcrumbs">
            <h3 v-if="existFilters">
                <RouterLink class="section" to="/">
                    <span>{{$t('home')}}</span>
                </RouterLink>
                {{$t('by')}}
                <template v-if="! activeModels">
                    <template v-if="activeCategories">
                        <span class="section">
                            <span class="title">{{ $t('categories') }}</span>
                            <span v-for="(category, index) in activeCategories" :key="index" class="filter">
                                <RouterLink :to="`/${computeUrlUntil(computedCategoriesUrl, category.slug)}`">
                                    {{category?.name}}
                                </RouterLink>
                            </span>
                        </span>
                    </template>
                    <template v-if="activeModel">
                        <span class="section">
                            <span class="title">{{ $t('model') }}</span>
                            <span class="model">
                                <RouterLink :to="computedModelUrl">
                                    {{activeModel.title.rendered}}
                                </RouterLink>
                            </span>
                        </span>
                    </template>
                    <template v-if="activeGalleries === true">
                        <RouterLink class="section" :to="`${computedModelUrl}/galleries`">
                            <span :class="{title: activeGallery}">{{ $t('galleries') }}</span>
                            <template v-if="activeGallery">
                                <RouterLink class="section" :to="computedGalleryUrl">
                                    <span class="gallery">{{activeGallery.name}}</span>
                                </RouterLink>
                            </template>
                        </RouterLink>
                    </template>
                    <template v-if="activeFileTypes">
                        <span class="section">
                            <span class="title">
                                <span>{{ $t('filetypes') }}</span>
                            </span>
                            <span v-for="(fileType, index) in activeFileTypes" :key="index" class="filter">
                                <RouterLink :to="`/${computeUrlUntil(computedFileTypesUrl, fileType.slug)}`" v-if="fileType">
                                    {{fileType.name}}
                                </RouterLink>
                            </span>
                        </span>
                    </template>
                    <template v-if="activeBySpaces">
                        <span class="section">
                            <span class="title">
                                <span>{{ $t('spaces') }}</span>
                            </span>
                            <span v-for="(bySpace, index) in activeBySpaces" :key="index" class="filter">
                                <RouterLink :to="`/${computeUrlUntil(computedBySpacesUrl, bySpace.slug)}`" v-if="bySpace">
                                    {{bySpace.name}}
                                </RouterLink>
                            </span>
                        </span>
                    </template>
                    <template v-if="activeMarketingMaterials">
                        <span class="section">
                            <span class="title">
                                <span>{{ $t('marketing-materials') }}</span>
                            </span>
                            <span v-for="(marketingMaterial, index) in activeMarketingMaterials" :key="index" class="filter">
                                <RouterLink :to="`/${computeUrlUntil(computedMarketingMaterialsUrl, marketingMaterial.slug)}`">
                                    {{marketingMaterial.name}}
                                </RouterLink>
                            </span>
                        </span>
                    </template>
                </template>
            </h3>
            <h3 v-else-if="route.name == 'search'">
                {{ $t('search') }} <span v-if="route.params.terms"> > </span>
                <span v-for="(term, index) in route.params.terms" :key="index" class="filter">
                    {{term.replaceAll('-','')}}
                </span>
            </h3>
            <h3 v-else-if="pageTitle">
                <template v-if="isPageTitleArray">
                    <template v-for="(title, index) in pageTitle" :key="index">
                        <template v-if="title === pageTitle[0]">
                            <RouterLink :to="{name: pageTitle[0]}">
                                {{ $t(title) }}
                            </RouterLink>
                        </template>
                        <template v-else>{{$t(title)}}</template>
                        <template v-if="pageTitle.length-1 !== index"> > </template>
                    </template>
                </template>
                <template v-else>
                    {{$t(pageTitle)}}
                </template>
            </h3>
        </div>
        <div class="actions">
            <div v-if="existFilters && countSelectedResources > 0" @click="removeAllFromSelection">
                {{ $t('remove-all-from-selection') }}
            </div>
            <div class="navigation-back" @click="goBack()" v-else-if="hasLastPage">
                <span>&lt; &nbsp; {{$t('go-back')}} &nbsp;</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useSettingStore } from "@/store/settings";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useModelStore } from "@/store/models";
import { useProductStore } from "@/store/products";
import { useFileTypeStore } from "@/store/filetypes";
import { useResourceStore } from "@/store/resources";
import { useMarketingMaterialStore } from "@/store/marketingMaterial";
import { useBySpacesStore } from "@/store/bySpaces";

const route = useRoute();
const router = useRouter();

const productStore = useProductStore();
const fileTypeStore = useFileTypeStore();
const bySpacesStore = useBySpacesStore();
const marketingMaterialStore = useMarketingMaterialStore();
const modelStore = useModelStore();
const { countSelectedResources } = storeToRefs(useResourceStore());
const { removeAllFromSelection } = useResourceStore();

const { pageTitle } = storeToRefs(useSettingStore());

let activeCategories = ref(undefined);
let activeFileTypes = ref(undefined);
let activeMarketingMaterials = ref(undefined);
let activeModels = ref(undefined);
let activeModel = ref(undefined);
let activeGallery = ref(undefined);
let activeGalleries = ref(undefined);
let activeBySpaces = ref(undefined);

const isPageTitleArray = computed(() => {
    return Array.isArray(pageTitle.value);
});

computeFilters(route);

router.afterEach((to) => {
    computeFilters(to);
});

function computeFilters(route) {
    const { categories, fileTypes, marketingMaterials, model, gallery, models, bySpaces } = route.params;

    activeModels.value = models;
    if (!models) {
        if (categories) activeCategories.value = categories.map(slug => productStore.getProductBySlug(slug));
        else activeCategories.value = undefined;

        if (fileTypes) activeFileTypes.value = fileTypes.map(slug => fileTypeStore.getFileTypeBySlug(slug));
        else activeFileTypes.value = undefined;

        if (marketingMaterials) activeMarketingMaterials.value = marketingMaterials.map(slug => marketingMaterialStore.getMarketingMaterialBySlug(slug));
        else activeMarketingMaterials.value = undefined;

        if (bySpaces) activeBySpaces.value = bySpaces.map(slug => bySpacesStore.getBySpacesBySlug(slug));
        else activeBySpaces.value = undefined;

        if (route.path.includes('/galleries')) activeGalleries.value = true;
        else activeGalleries.value = false;

        if (model) {
            activeModel.value = modelStore.getModelBySlug(model);

            if (gallery) {
                const currentModelGalleries = modelStore.getGalleriesByModelSlug(activeModel.value.slug);
                const currentGallery = currentModelGalleries?.children?.find(element => {
                    return gallery === element.slug;
                });
                activeGallery.value = currentGallery;
            } else activeGallery.value = undefined;

        } else activeModel.value = undefined;
    }
}

const existFilters = computed(() => activeCategories.value !== undefined || activeFileTypes.value !== undefined || activeMarketingMaterials.value !== undefined || activeBySpaces.value !== undefined);
const computedCategoriesUrl = computed(() => `categories/${activeCategories.value?.map(category => category?.slug).join('/')}`);
const computedFileTypesUrl = computed(() => {
    let result = `file-types/${activeFileTypes.value?.map(fileType => fileType?.slug).join('/')}`;
    if (activeCategories.value?.length > 0) result = `${computedCategoriesUrl.value}/${result}`;
    return result;
});
const computedMarketingMaterialsUrl = computed(() => {
    let result = `marketing-materials/${activeMarketingMaterials.value?.map(marketingMaterial => marketingMaterial?.slug).join('/')}`;
    if (activeFileTypes.value?.length > 0) result = `file-types/${activeFileTypes.value.map(fileType => fileType.slug).join('/')}/${result}`;
    if (activeCategories.value?.length > 0) result = `categories/${activeCategories.value.map(category => category.slug).join('/')}/${result}`;
    if (activeBySpaces.value?.length > 0) result = `by-spaces/${activeBySpaces.value.map(bySpace => bySpace.slug).join('/')}/${result}`;
    return result;
});
const computedModelUrl = computed(() => {
    let result = `model/${activeModel.value?.slug}`;
    if (activeCategories.value?.length > 0) result = `/categories/${activeCategories.value.map(category => category.slug).join('/')}/${result}`;
    return result;
});
const computedGalleryUrl = computed(() => {
    return `${computedModelUrl.value}/galleries/${activeGallery.value?.slug}`;
});
const computedBySpacesUrl = computed(() => {
    let result = `by-spaces/${activeBySpaces.value?.map(bySpace => bySpace?.slug).join('/')}`;
    if (activeFileTypes.value?.length > 0) result = `file-types/${activeFileTypes.value.map(fileType => fileType.slug).join('/')}/${result}`;
    if (activeCategories.value?.length > 0) result = `categories/${activeCategories.value.map(category => category.slug).join('/')}/${result}`;
    if (activeMarketingMaterials.value?.length > 0) result = `marketing-materials/${activeMarketingMaterials.value.map(marketingMaterial => marketingMaterial.slug).join('/')}/${result}`;
    return result;
});

const computeUrlUntil = (url, until) => {
    let result = url;
    while (result.includes(until)) {
        result = result.substring(0, result.lastIndexOf(until));
    }
    result += until;
    return result;
};
</script>

<script>
export default {
    data() {
        return {
            hasLastPage: false,
            lastPageTitle: undefined,
            isLastPageTitleTranslated: false,
        }
    },
    methods: {
        goBack() {
            this.$router.back();
        }
    },
    watch: {
        $route() {
            this.hasLastPage = this.$router.options.history.state.back !== null && ['my-selection', 'download-history', 'account', 'projects'].includes(this.$route.name);

            if (this.hasLastPage) {
                const lastRoute = this.$router.resolve(this.$router.options.history.state.back)
                this.lastPageTitle = lastRoute.meta.title?.name;
                this.isLastPageTitleTranslated = lastRoute.meta.title?.isTranlated;
            }
        }
    }
};
</script>

<style scoped lang="scss">
h3 {
    @include breadcrumbs-title();

    @include for-phone-only() {
        display: none;
    }

}

h3 a,
h3 span {
    .title {
        &::after {
            content: ' > ';
        }
    }
}

.filter:not(:last-child)::after {
    content: ', ';
}

.section:not(:last-child)::after {
    content: ' | '
}

.actions {
    cursor: pointer;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px; //Cambiado desde 20px.
    column-gap: 10px;

    @include breadcrumbs-title();

    &-back {
        white-space: nowrap;
        cursor: pointer;

        &-page-name {
            text-transform: capitalize;
        }
    }

}
</style>